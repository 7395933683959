<template lang="">
  <div
    v-if="ehVisualizar"
    class="row"
  >
    <div
      class="RoteiroHistorico"
      style="position: relative; padding: 25px; width: 100%"
    >
      <v-stepper vertical>
        <template v-for="(item, index) in historico">
          <v-stepper-step
            :key="item.id"
            complete
            :step="`${index + 1}`"
            :complete-icon="tiposHistorico[`${item.situacao}`]?.icone"
            :color="tiposHistorico[`${item.situacao}`]?.cor"
          >
            <div
              v-if="
                checarSituacao(item.situacao) == 'CHECKOUT' &&
                  item.tipoEnvioMaterial
              "
              class="historico-envio"
            >
              <span>{{ item.dataOcorrencia | formatarDataHora }}</span>
              <div class="d-flex flex-column">
                <span>
                  <b>{{ tiposHistorico[`${item.situacao}`]?.enum }}</b>
                  {{
                    $t(
                      `modulos.roteiro_envio_material.historico.${item.tipoEnvioMaterial}`,
                      { codigo: item.codigo }
                    )
                  }}
                  <span>{{
                    $t(`modulos.roteiro_envio_material.historico.texto.por`)
                  }}
                  </span>
                  <b>{{ item.usuario.nome }}</b></span>
                <span>{{
                  $t(
                    'modulos.roteiro_envio_material.historico.texto.agendamento'
                  )
                }}: <b>{{ item.dataAgendamento | formatarData }}</b> -
                  {{
                    $t(
                      'modulos.roteiro_envio_material.historico.texto.participante'
                    )
                  }}: <b>{{ item.cliente }}</b></span>
              </div>
            </div>
            <div
              v-else-if="checarSituacao(item.situacao) == 'CHECKIN'"
              class="historico-envio"
            >
              <span>{{ item.dataOcorrencia | formatarDataHora }}</span>
              <div class="d-flex flex-column">
                <span>
                  <b>{{ tiposHistorico[`${item.situacao}`]?.enum }}</b>
                  {{
                    $t(
                      'modulos.roteiro_envio_material.historico.texto.no_cliente'
                    )
                  }}
                  <b>{{ item.usuario.nome }}</b></span>
                <span>{{
                  $t(
                    'modulos.roteiro_envio_material.historico.texto.agendamento'
                  )
                }}: <b>{{ item.dataAgendamento | formatarData }}</b> -
                  {{
                    $t(
                      'modulos.roteiro_envio_material.historico.texto.participante'
                    )
                  }}: <b>{{ item.cliente }}</b></span>
              </div>
            </div>
            <div
              v-else
              class="historico-envio"
            >
              <span>{{ item.dataOcorrencia | formatarDataHora }}</span>
              <div class="d-flex flex-column">
                <span>
                  <b>{{ verificarSeEhFinalizacaoDoRoteiro(item.situacao) }}</b>
                  {{ $t('modulos.roteiro_envio_material.historico.texto.por') }}
                  <b>{{ item.usuario.nome }}</b></span>
                <span>{{
                  $t(
                    'modulos.roteiro_envio_material.historico.texto.agendamento'
                  )
                }}: <b>{{ item.dataAgendamento | formatarData }}</b>
                </span>
              </div>
            </div>
          </v-stepper-step>
          <v-stepper-content
            :key="`${item.id} ${index}`"
            :step="`${index + 1}`"
          />
        </template>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  filters: {
    formatarDataHora: function (data) {
      return helpers.formatarDataBr(data, true);
    },
    formatarData: function (data) {
      return helpers.formatarDataBr(data);
    },
  },
  props: {
    ehVisualizar: {
      type: Boolean,
      default: false,
    },
    historico: {
      type: Array,
      default: () => [],
    },
    situacao: {
      type: String,
      default: '',
    },
    contatoAtendimento: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tiposHistorico: {
        Criado: {
          icone: '$plus',
          cor: '#35507B',
          enum: this.$t('modulos.roteiro_envio_material.historico.Criado'),
        },
        Programado: {
          icone: '$clockOutline',
          cor: '#D0D5DD',
          enum: this.$t('modulos.roteiro_envio_material.historico.Programado'),
        },
        Reagendado: {
          icone: '$calendar',
          cor: '#F04438',
          enum: this.$t('modulos.roteiro_envio_material.historico.Reagendado'),
        },
        Concluido: {
          icone: '$mdiCheck',
          cor: '#12B76A',
          enum: this.$t('modulos.roteiro_envio_material.historico.Concluido'),
        },
        Alterado: {
          icone: '$mdiRefresh',
          cor: '#F79009',
          enum: this.$t('modulos.roteiro_envio_material.historico.Alterado'),
        },
        Checkin: {
          icone: '$playCircleOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.roteiro_envio_material.historico.Checkin'),
        },
        CheckinDesfeito: {
          icone: '$mdiRefresh',
          cor: '#F79009',
          enum: this.$t('modulos.envio_material.historico.CheckinDesfeito'),
        },
        Reagendamento: {
          icone: '$fileCheckOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t(
            'modulos.roteiro_envio_material.historico.Reagendamento'
          ),
        },
        Cancelamento: {
          icone: '$close',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t(
            'modulos.roteiro_envio_material.historico.Cancelamento'
          ),
        },
        Normal: {
          icone: '$playCircleOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.roteiro_envio_material.historico.Normal'),
        },
        EmExecucao: {
          icone: '$playCircleOutline',
          cor: 'rgba(53, 80, 123, 0.70)',
          enum: this.$t('modulos.roteiro_envio_material.historico.EmExecucao'),
        },
        Finalizado: {
          icone: '$mdiCheck',
          cor: '#12B76A',
          enum: this.$t('modulos.roteiro_envio_material.historico.Finalizado'),
        },
      },
      opcoes: {
        EnumSituacaoEnvioMaterial: [],
      }
    };
  },

  async mounted() {
    await this.buscarEnumSituacaoEnvioMaterial();
    console.log(this.historico)
  },
  methods: {
    verificarSeEhFinalizacaoDoRoteiro: function (situacao) {
      if (situacao == 'Concluido') return 'Finalizado';
      else return this.tiposHistorico[`${situacao}`]?.enum;
    },
    checarSituacao: function (situacao) {
      const checkouts = [
        'Cancelado',
        'Reagendado',
        'Concluido',
        'SemAgendamento',
      ];
      if (checkouts.includes(situacao)) {
        return 'CHECKOUT';
      } else if (situacao == 'Checkin') {
        return 'CHECKIN';
      }
    },
    buscarEnumSituacaoEnvioMaterial: async function () {
      await EnumeradorService.buscar('EnumSituacaoEnvioMaterial').then(
        (res) => {
          this.opcoes.EnumSituacaoEnvioMaterial = res;
        }
      );
    },
    situacaoEnum: function (value) {
      if (value)
        return this.opcoes.EnumSituacaoEnvioMaterial.find(
          (el) => el.value === value
        )?.text;

      return '';
    },
  },
};
</script>
<style lang="scss">
.RoteiroHistorico {
  .historico-envio {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .historico-envio + .historico-envio {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    .v-stepper__step__step {
      padding: 10px !important;
    }
  }

  .v-sheet.v-stepper {
    box-shadow: none !important;
  }

  .v-stepper__step {
    padding-left: 0 !important;
    padding-right: 0 !important;
    svg {
      width: 20px !important;
    }
  }

  .v-stepper__content {
    margin-left: 16px !important;
  }

  .v-stepper__step__step {
    padding: 18px;
  }
}
</style>
