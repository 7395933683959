<template>
  <modal-padrao
    ref="modal-reagendamento"
    :titulo="$t('modulos.roteiro_envio_material.modal.reagendar_popup.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="salvarReagendamento"
  >
    <v-form
      ref="form"
      class="row"
    >
      <span class="col-12 mt-2">
        {{ $t('modulos.roteiro_envio_material.modal.reagendar_popup.descricao') }}
      </span>
      <input-date
        v-model="dataAgendamento"
        :label="$t('modulos.roteiro_envio_material.modal.reagendar_popup.data_agendamento')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12"
        :allowed-days="diasDisponiveis"
        apenas-datas-futuras
        obrigatorio
      />
    </v-form>
    <v-divider />
  </modal-padrao>
</template>
<script>
export default {
  props: {
    clientes: {}
  },
  data: function () {
    return {
      dataAgendamento: null,
      diasDisponiveis: []

    }
  },
  methods: {
    abrirModal(diasDisponiveis) {
      this.$refs['modal-reagendamento'].abrirModal()
      this.diasDisponiveis = this.diasEnvioDisponiveisParaTodosOsClientes(diasDisponiveis)

    },
    salvarReagendamento() {
      const valido = this.$refs['form'].validate()
      if(!valido) return
      const reagendamentoCliente = this.clientes.map((cliente) => {
        return{
          ...cliente,
          dataAgendamento: this.dataAgendamento.substring(0,10)
        }
      })
      this.$emit('excluir', reagendamentoCliente)
      this.$refs['modal-reagendamento'].fecharModal()
    },

    diasEnvioDisponiveisParaTodosOsClientes(diasDisponiveisArray) {
      if (!Array.isArray(diasDisponiveisArray) || diasDisponiveisArray.length === 0) {
        return [];
      }
      let DiasDisponiveisPorCliente = diasDisponiveisArray.map(cliente => {
        return cliente.diasDisponiveis
      })


      const resultado = [];

      for (let i = 0; i < 5; i++) {
        const todosTrue = DiasDisponiveisPorCliente.every(arrayInterno => arrayInterno[i] === true);
        resultado.push(todosTrue);
      }
      
       return resultado.map((dia, index) => {
        if (dia) {
          return index
          }
        })
        .filter(dia => dia !== undefined) 
    }
  },
}
</script>
