import _ from 'lodash';

export class RoteiroEnvioMaterialModel {
  constructor({
    id,
    codigo,
    dataAgendamento = new Date().toDateString(),
    clientes = [],
    clientesReagendados = [],
    historico = []

  }) {
    this.id = id;
    this.codigo = codigo;
    this.dataAgendamento = dataAgendamento;
    this.clientes = clientes?.map((cliente) => { return { ...cliente, flagJaExiste: true } })
    this.clientesReagendados = clientesReagendados
    this.historico = historico
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.dataAgendamento = retorno.dataAgendamento.substring(0, 10)

    if (!retorno.id) {
      retorno.clientesIds = retorno.clientes?.map((cliente) => cliente.id);

      return retorno
    }

    retorno.clientes = retorno.clientes?.map((cliente) => {
      if (cliente.flagJaExiste)
        return {
          id: cliente.id,
          clienteId: cliente.clienteId,
        }

      return {
        id: null,
        clienteId: cliente.id,
      }
    });

    retorno.clientesReagendados?.forEach((cliente) => {
      retorno.clientes.push(
        {
          id: cliente.id,
          clienteId: cliente.clienteId,
          dataAgendamento: cliente.dataAgendamento
        })
    })

    return retorno;
  }
}
