<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <v-form ref="form" class="row mt-2" :disabled="visualizar">
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-3"
        :label="$t('modulos.roteiro_envio_material.formulario.codigo')"
        disabled
      />
      <input-date
        v-model="form.dataAgendamento"
        class="col-12 col-md-4"
        :label="
          $t('modulos.roteiro_envio_material.formulario.data_agendamento')
        "
        apenas-datas-futuras
        obrigatorio
        :disabled="tabela.dados.length > 0 || visualizar"
      />
    </v-form>
    <div>
      <div class="d-flex align-center justify-space-between mt-2">
        <div class="d-flex align-center">
          <botao-padrao
            color="secondary"
            outlined
            :disabled="!podeExcluir || visualizar"
            @click="movimentarItem('cima')"
          >
            <v-icon> $arrowUp </v-icon>
          </botao-padrao>

          <botao-padrao
            color="secondary"
            outlined
            class="mx-2"
            :disabled="!podeExcluir || visualizar"
            @click="movimentarItem('baixo')"
          >
            <v-icon> $arrowDown </v-icon>
          </botao-padrao>

          <botao-padrao
            v-if="podeExcluir"
            :disabled="visualizar"
            class="my-2 ml-2"
            outlined
            color="danger"
            @click="reagendarCliente"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
            {{ $t('geral.botoes.excluir') }}
          </botao-padrao>
        </div>
        <botao-padrao
          :disabled="!form.dataAgendamento || visualizar"
          @click="abrirModal"
        >
          {{
            $t('modulos.roteiro_envio_material.formulario.adicionar_cliente')
          }}
        </botao-padrao>
      </div>

      <tabela-padrao
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.dados.length"
        sem-acoes
        sem-paginacao
        :mostrar-checkbox="!visualizar"
      >
        <template v-slot:item.ordem="{ item }">
          {{ item.index + 1 }}
        </template>
        <template v-slot:item.diasDisponiveis="{ item }">
          <div class="chipsDias">
            <v-chip
              v-for="dia in transformarFlagDiasEmDiasSemans(
                item.item.diasDisponiveis
              )"
              :key="dia"
              class="mr-1"
              small
            >
              {{ dia }}
            </v-chip>
          </div>
        </template>
      </tabela-padrao>
    </div>
    <visualizar-historico-roteiro
      :historico="formarHistorico()"
      :eh-visualizar="visualizar"
    />

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="visualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <template v-else>
        <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="salvar">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </template>
    </div>
    <template>
      <cliente-modal
        ref="cliente-modal"
        :data-envio="form.dataAgendamento"
        :clientes-cadastrados="tabela.dados"
      />
    </template>
    <template>
      <reagendamento-modal
        ref="reagendamento-modal"
        :clientes="tabela.selecionados"
        @excluir="excluir"
      />
    </template>
  </div>
</template>
<script>
import ClienteModal from './components/ClienteModal';
import RoteiroEnvioMaterialService from '@/common/services/cadastros/RoteiroEnvioMaterialService';
import { RoteiroEnvioMaterialModel } from '@/common/models/cadastros/RoteiroEnvioMaterialModel';
import helpers from '@/common/utils/helpers.js';
import ReagendamentoModal from './components/ReagendamentoModal.vue';
import VisualizarHistoricoRoteiro from './components/VisualizarHistoricoRoteiro.vue';
import { sortBy } from 'lodash';
export default {
  components: {
    ClienteModal,
    ReagendamentoModal,
    VisualizarHistoricoRoteiro,
  },
  props: {
    id: { type: String, default: '' },
    idInstrumento: { type: String, default: '' },
    atualizaFormulario: { type: Number, default: 1 },
  },
  data() {
    return {
      visualizar: false,
      form: new RoteiroEnvioMaterialModel({}),
      clientesReagendados: [],
      valido: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'ordem',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.ordem'
            ),
            sortable: false,
            width: '50',
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.cliente'
            ),
            sortable: false,
            width: '150',
          },
          {
            value: 'endereco',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.endereco'
            ),
            sortable: false,
            formatter: (endereco) => {
              if (endereco) {
                return `${endereco.logradouro}, ${endereco.numero}, ${endereco.bairro}, ${endereco.municipio.nome}, ${endereco.cep} - ${endereco.unidadeFederativa.nome}`;
              }
            },
          },
          {
            value: 'quantidadeEnviosMateriaisEntrega',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.qtd_entregas'
            ),
            sortable: false,
          },
          {
            value: 'quantidadeEnviosMateriaisRecebimento',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.qtd_recebimentos'
            ),
            sortable: false,
          },
          {
            value: 'dataSolicitacaoMaisAntiga',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.data_solicitacao_mais_antiga'
            ),
            sortable: false,
            formatter: (data) => {
              if (data) {
                return helpers.formatarDataBr(data);
              }
            },
          },
          {
            value: 'diasDisponiveis',
            text: this.$t(
              'modulos.roteiro_envio_material.formulario.tabela.dias_cliente_permite_envio_material'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id)
        return this.$t('modulos.roteiro_envio_material.titulos.editar');
      return this.$t('modulos.roteiro_envio_material.titulos.novo');
    },
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },

  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'RoteiroEnvioMaterial', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'RoteiroEnvioMaterial', 'Inserir');

    this.form.instrumentoId = this.idInstrumento;

    const { name } = this.$route;

    if (name === 'roteiro-envio-material-visualizar') {
      this.visualizar = true;
    }
  },
  methods: {
    abrirModal: function () {
      this.$refs['cliente-modal'].abrirModal();
      this.$nextTick(() => {
        this.$refs['cliente-modal'].listar();
      });
    },
    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
      });
    },
    buscar: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.buscar(id)
        .then((res) => {
          this.form = new RoteiroEnvioMaterialModel(res.data);
          this.tabela.dados = this.form.clientes;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    reagendarCliente: function () {
      const clientesSemReagendamento = this.tabela.selecionados.filter(
        (cliente) => {
          return !cliente.clienteId;
        }
      );

      clientesSemReagendamento?.forEach((cliente) => {
        this.tabela.dados.splice(this.form.clientes.indexOf(cliente), 1);
        this.tabela.selecionados.splice(this.form.clientes.indexOf(cliente), 1);
      });

      const PeloMenosUmReagendado = this.tabela.selecionados.find(
        (cliente) => cliente.clienteId
      );

      if (PeloMenosUmReagendado)
        this.$refs['reagendamento-modal'].abrirModal(this.tabela.selecionados);
    },

    excluir: function (reagendados) {
      reagendados.forEach((reagendado) =>
        this.form.clientesReagendados.push(reagendado)
      );
      this.tabela.selecionados.forEach((cliente) => {
        this.tabela.dados.splice(this.form.clientes.indexOf(cliente), 1);
      });
      this.tabela.selecionados = [];
    },

    transformarFlagDiasEmDiasSemans(arrayFlagDias) {
      return helpers.converterFlagDiasParaDiasSemana(arrayFlagDias);
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'roteiro-envio-material' });
      });
    },
    voltar: function () {
      this.$router.back();
    },
    salvar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.clientes = this.tabela.dados;
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.roteiro_envio_material.cadastro_sucesso')
          );
          this.$router.push({ name: 'roteiro-envio-material' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formarHistorico: function () {
      const historicosArray = [];
      const situacoesPermitidas = [
        'Checkin',
        'CheckinDesfeito',
        'Cancelado',
        'Reagendado',
        'Concluido',
        'SemAgendamento',
      ];
      this.form.clientes.forEach((cliente) => {
        if (cliente.enviosMateriais)
          cliente.enviosMateriais.forEach((envio) => {
            if (envio.historico)
              envio.historico.forEach((historico) => {
                if (situacoesPermitidas.includes(historico.situacao))
                  historicosArray.push({
                    ...historico,
                    cliente: cliente.nome,
                    tipoEnvioMaterial: envio.tipoEnvioMaterial,
                    codigo: envio.codigo,
                  });
              });
          });
      });
      const historicos = [
        ...historicosArray.flat(Infinity),
        ...this.form.historico,
      ];

      const historicoOrdenado = sortBy(historicos, 'dataOcorrencia').reverse();
      return historicoOrdenado;
    },
  },
};
</script>
<style lang="scss" scoped>
.chipsDias {
  display: flex;
  padding: 6px 0 2px 0;
  gap: 0.25rem;
}
</style>
