<template lang="">
  <modal-padrao
    ref="modal"
    max-width="90%"
    :titulo="$t('modulos.roteiro_envio_material.modal.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="associarCliente"
  >
    <div
      class="d-flex justify-space-between align-end"
    >
      <input-text
        v-model="filtroAplicado.nome"
        class="w-90"
        :label="$t('modulos.roteiro_envio_material.modal.filtro.nome')"
      />
      <input-text
        v-model="filtroAplicado.endereco"
        class="w-90"
        :label="$t('modulos.roteiro_envio_material.modal.filtro.endereco')"
      />
      <botao-padrao
        color="secundary"
        outlined
        blocked
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      sem-acoes
    />
  </modal-padrao>
</template>
<script>
import {EnvioMaterialService} from '@/common/services/cadastros'
import helpers from '@/common/utils/helpers'
export default {
  props:{
    clientesCadastrados:{
      type: Array, default: ()=>[]
    },
    dataEnvio:{type: String,default: null}
  },
    data(){
      return{
        tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.roteiro_envio_material.modal.tabela.nome'),
            sortable: true,
          },
          {
            value: 'endereco',
            text: this.$t('modulos.roteiro_envio_material.modal.tabela.endereco'),
            sortable: true,
            formatter: (endereco) => {
              if(endereco){
                return `${endereco.logradouro}, ${endereco.numero}, ${endereco.bairro}, ${endereco.municipio.nome}, ${endereco.cep} - ${endereco.unidadeFederativa.nome}`
              }
            },
          },
          {
            value: 'quantidadeEnviosMateriaisEntrega',
            text: this.$t('modulos.roteiro_envio_material.modal.tabela.qtd_entregas'),
            sortable: true,
          },
          {
            value: 'quantidadeEnviosMateriaisRecebimento',
            text: this.$t('modulos.roteiro_envio_material.modal.tabela.qtd_recebimentos'),
            sortable: true,
          },
          {
            value: 'dataSolicitacaoMaisAntiga',
            text: this.$t('modulos.roteiro_envio_material.modal.tabela.data_solicitacao_mais_antiga'),
            sortable: true,
            formatter: (data)=>{
              if(data){
                return helpers.formatarDataBr(data)
              }
            },          
          },

        ],     
      },
        filtroAplicado: {
        nome: "",
        endereco: "",
      },
      }
    },
    methods:{
      abrirModal: function(){
        this.$refs.modal.abrirModal()
        this.tabela.selecionados = this.clientesCadastrados?.map((cliente)=>{ return{ ...cliente, id:cliente.clienteId || cliente.id}})
      },
      listar: function(){
        let parametros = {}
        if(this.dataEnvio)
          parametros.dataEnvio = this.dataEnvio.substring(0,10)

        EnvioMaterialService.buscarClienteRoteiro(parametros).then((res)=>{
          this.tabela.dados = res.data
        })
      },
      associarCliente: function(){

        this.tabela.selecionados.forEach((cliente)=>{

        const clientJaAdicionado =  this.clientesCadastrados.find((clienteCadastrado)=>{
            return (clienteCadastrado.clienteId || clienteCadastrado.id) === cliente.id
          }) 

          if(!clientJaAdicionado)
            this.clientesCadastrados.push(cliente)
        })
      this.$refs['modal'].fecharModal();
      }
    }
}
</script>
